export const siteUrlShort = 'https://lasercorrection.bg';
export const siteUrl = `${siteUrlShort}/`;
export enum Routes {
  HOME = '/',
  CONTACTS = '/contacts/',
  ASTIGMATISM = '/astigmatism/',
  DR_GEORGI_TASKOV = '/dr-georgi-taskov/',
  DR_DIMITAR_TASKOV = '/dr-dimitar-taskov/',
  DR_KARPAROVA = '/dr-svetoslava-karparova/',
  DR_TODOR_TASKOV = '/dr-todor-taskov/',
  HYPERMETROPIA = '/far-sightedness-hypermetropia/',
  ENGAGEMENT = '/engagement-program/',
  LASER_VISION = '/laser-vision-correction/',
  CAMPAIGNS = '/news-events-campaigns/',
  FINANCING = '/prices-financing/',
  MYOPIA = '/short-sightedness-myopia/',
  TEAM = '/team/',
  TECHNOLOGY = '/#technology',
  TESTIMONIALS = '/testimonials/',
  WHY_US = '/why-us/',
}

export enum Team {
  DR_GEORGI_TASKOV = 1,
  DR_DIMITAR_TASKOV,
  DR_TODOR_TASKOV,
  DR_KARPAROVA,
  TSVETKA_FILIPOVA,
  DR_ISKRA_SHISHKOVA,
  DR_VANIA_TOMOVA,
  DR_MILENA_GUDEVA,
  DR_IVANINA_RAICHEVA,
  DR_IVANKA_BOGDANOVA,
  DR_ROSICA_NIKOLOVA,
  DR_SONIA_DJURELOVA,
  DR_ELENA_GROZDANOVA,
  DR_MARIA_MINCHEVA,
  DR_ASPARUH_KIUCHUKOV,
  DR_ATANAS_GEORGIEV,
  DR_DILIANA_ALEKSANDROVA,
  DR_DESISLAVA_CHILINGIROVA,
  DR_ZDRAVENA_DOBCHEVA,
  DR_KRISTINA_PETKOVA,
  DR_RAMAZAN_SALIMEHMED,
  DR_VESELA_MITKOVA,
  DR_NINA_STOYANOVA,
  DR_ELENA_DIMITROVA,
  DR_MILEN_MARMAROV,
  DR_ELENA_KOLEVA,
  DR_ALEXANDRA_STEFANOVA,
  DR_HANA_PETKOVA,
  ELITSA_STANKOVA,
  TEMENUJKA_HADJIEVA,
  NINA_STOYKOVA,
  IVAYLA_HADJIEVA,
  DENITSA_TARAPANOVA,
  KATYA_YANKULOVA,
  STOYANKA_PETROVA,
  STANISLAVA_KOVACHEVA,
  YASEMIN_KEMAL,
  DANIELA_PRAZOVA,
  SVETLA_MITEVA,
  GYULBIE_KEHAYOVA,
  DIYANA_BOGDANOVA,
  KALINA_PETROVA,
  PETYA_DOBRYANOVA,
  GERGANA_DAMBALOVA,
  TANYA_BELEVA,
  DR_SVETOSLAVA_KARPAROVA,
}

export const TeamMapping = {
  [Team.DR_TODOR_TASKOV]: {
    img: '/img/team/2.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_DIMITAR_TASKOV]: {
    img: '/img/team/1.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_GEORGI_TASKOV]: {
    img: '/img/team/3.webp',
    text: 'g.ophthalmologist',
  },
  [Team.TSVETKA_FILIPOVA]: {
    img: '/img/team/15.webp',
    text: 'g.manager',
  },
  [Team.DR_ISKRA_SHISHKOVA]: {
    img: '/img/team/18.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_VANIA_TOMOVA]: {
    img: '/img/team/17.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_MILENA_GUDEVA]: {
    img: '/img/team/19.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_IVANINA_RAICHEVA]: {
    img: '/img/team/6.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_IVANKA_BOGDANOVA]: {
    img: '/img/team/7.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_ALEXANDRA_STEFANOVA]: {
    img: '/img/team/alexandra-stefanova.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_HANA_PETKOVA]: {
    img: '/img/team/hana-petkova.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_ROSICA_NIKOLOVA]: {
    img: '/img/team/20.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_SONIA_DJURELOVA]: {
    img: '/img/team/30.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_ELENA_GROZDANOVA]: {
    img: '/img/team/28.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_MARIA_MINCHEVA]: {
    img: '/img/team/29.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_ASPARUH_KIUCHUKOV]: {
    img: '/img/team/a-kyuchukov.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_ATANAS_GEORGIEV]: {
    img: '/img/team/36.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_DILIANA_ALEKSANDROVA]: {
    img: '/img/team/37.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_DESISLAVA_CHILINGIROVA]: {
    img: '/img/team/38.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_ZDRAVENA_DOBCHEVA]: {
    img: '/img/team/39.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_KRISTINA_PETKOVA]: {
    img: '/img/team/40.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_RAMAZAN_SALIMEHMED]: {
    img: '/img/team/41.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_VESELA_MITKOVA]: {
    img: '/img/team/35.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_NINA_STOYANOVA]: {
    img: '/img/team/34.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_ELENA_DIMITROVA]: {
    img: '/img/team/e-dimitrova.webp',
    text: 'g.ophthalmologist',
  },
  [Team.DR_MILEN_MARMAROV]: {
    img: '/img/team/8.webp',
    text: 'g.anesthesia',
  },
  [Team.DR_ELENA_KOLEVA]: {
    img: '/img/team/5.webp',
    text: 'g.anesthesia',
  },
  [Team.ELITSA_STANKOVA]: {
    img: '/img/team/42.webp',
    text: 'g.head-nurse',
  },
  [Team.TEMENUJKA_HADJIEVA]: {
    img: '/img/team/14.webp',
    text: 'g.nurse',
  },
  [Team.NINA_STOYKOVA]: {
    img: '/img/team/11.webp',
    text: 'g.nurse',
  },
  [Team.IVAYLA_HADJIEVA]: {
    img: '/img/team/10.webp',
    text: 'g.surgical-nurse',
  },
  [Team.DENITSA_TARAPANOVA]: {
    img: '/img/team/43.webp',
    text: 'g.surgical-nurse',
  },
  [Team.KATYA_YANKULOVA]: {
    img: '/img/team/23.webp',
    text: 'g.nurse',
  },
  [Team.STOYANKA_PETROVA]: {
    img: '/img/team/26.webp',
    text: 'g.nurse',
  },
  [Team.STANISLAVA_KOVACHEVA]: {
    img: '/img/team/44.webp',
    text: 'g.nurse',
  },
  [Team.YASEMIN_KEMAL]: {
    img: '/img/team/45.webp',
    text: 'g.nurse',
  },
  [Team.DANIELA_PRAZOVA]: {
    img: '/img/team/33.webp',
    text: 'g.nurse',
  },
  [Team.SVETLA_MITEVA]: {
    img: '/img/team/46.webp',
    text: 'g.nurse',
  },
  [Team.GYULBIE_KEHAYOVA]: {
    img: '/img/team/32.webp',
    text: 'g.nurse',
  },
  [Team.DIYANA_BOGDANOVA]: {
    img: '/img/team/4.webp',
    text: 'g.registration',
  },
  [Team.KALINA_PETROVA]: {
    img: '/img/team/k-petrova.webp',
    text: 'g.registration',
  },
  [Team.PETYA_DOBRYANOVA]: {
    img: '/img/team/12.webp',
    text: 'g.registration',
  },
  [Team.GERGANA_DAMBALOVA]: {
    img: '/img/team/47.webp',
    text: 'g.registration',
  },
  [Team.TANYA_BELEVA]: {
    img: '/img/team/16.webp',
    text: 'g.vip-registration',
  },
  [Team.DR_SVETOSLAVA_KARPAROVA]: {
    img: '/img/team/27.webp',
    text: 'g.project-manager',
  },
};

export enum Patients {
  TODOR = 1,
  LYUBA,
  RADOSLAV,
  IVAN_AND_ZHENY_LECHEVI,
  SAHAR_ZAVARI,
  IVA_TOPUZLIISKA,
  CLAUDIO,
  CEM_BEKIR,
  KAMELIA_MARINOVA,
  MIROSLAV,
  DELCHO,
  RADOMIR,
  VESELIN,
  NIKOLINKA,
  ZORNITSA,
  STOYAN,
  NADYA,
  TEODORA,
  DOBROMIR,
}

export const PatientsMapping = {
  [Patients.IVAN_AND_ZHENY_LECHEVI]: {
    image: ['/img/patients/lechev.webp', '/img/patients/j.lecheva.webp'],
    videoId: 'APvM52HHqhQ',
  },
  [Patients.SAHAR_ZAVARI]: {
    image: '/img/patients/s-zavari.webp',
    videoId: 'KdaZ8f1cbGU',
  },
  [Patients.IVA_TOPUZLIISKA]: {
    image: '/img/patients/i-topuziiska.webp',
    videoId: '9lWB7k7hfzM',
  },
  [Patients.CLAUDIO]: {
    image: '/img/patients/claudio.webp',
    videoId: 'd1ableR-hN4',
  },
  [Patients.CEM_BEKIR]: {
    image: '/img/patients/cem-bekir.webp',
    videoId: 'F0tL-dcj8qg',
  },
  [Patients.KAMELIA_MARINOVA]: {
    image: '/img/patients/k-marinova.webp',
    videoId: 'f_BcerjJjWM',
  },
  [Patients.MIROSLAV]: {
    image: '/img/patients/miroslav.webp',
    videoId: 'MXOV4bcUX8o',
  },
  [Patients.DELCHO]: {
    image: '/img/patients/delcho.webp',
    videoId: 'Un-LWv9TOhM',
  },
  [Patients.RADOMIR]: {
    image: '/img/patients/radomir.webp',
    videoId: 'Rt3vClDQIro',
  },
  [Patients.VESELIN]: {
    image: '/img/patients/veselin.webp',
    videoId: 'OljVJmCfwqk',
  },
  [Patients.NIKOLINKA]: {
    image: '/img/patients/nikolinka.webp',
    videoId: 'KJPixnv6lik',
  },
  [Patients.ZORNITSA]: {
    image: '/img/patients/zornitsa.webp',
    videoId: 'rUYFUBvrao0',
  },
  [Patients.STOYAN]: {
    image: '/img/patients/stoyan.webp',
    videoId: 'fQmOtE5qgzM',
  },
  [Patients.NADYA]: {
    image: '/img/patients/nadya.webp',
    videoId: '4VxuAf6WZOY',
  },
  [Patients.TODOR]: {
    image: '/img/patients/todor.webp',
    videoId: 'gsCT9B3GQqg',
  },
  [Patients.LYUBA]: {
    image: '/img/patients/luba.webp',
    videoId: 'pJk5jm-N77U',
  },
  [Patients.RADOSLAV]: {
    image: '/img/patients/radoslav.webp',
    videoId: 'H898on2frL8',
  },
  [Patients.TEODORA]: {
    image: '/img/patients/teodora.webp',
    videoId: 'pfaPL9ljfMo',
  },
  [Patients.DOBROMIR]: {
    image: '/img/patients/dobromir.webp',
    videoId: 'QauKulUhNA4',
  },
};
